
import React from 'react'
// import { Link } from 'gatsby'
// import PropTypes from "prop-types"
import Layout from '../components/layout'
import Footer from '../components/Footer'
import withLocation from "../components/withLocation"
import TransactionForm from "../components/TransactionForm"


const CreateTransaction = ({ search }) => {
  const { trhash } = search
  
  console.log(trhash)
  // const baclLink = trhash
  return(
  <Layout>
    <header id="header">
    <div className="offerta-logo">
        <span className="icon fa-handshake-o fa-2x "></span>
    </div>

    </header>
    
    <TransactionForm trhash={trhash} />
    <Footer/>
  </Layout>
  )
}

export default withLocation(CreateTransaction)
